import React, { useEffect, useState } from 'react';
import data from '../../data.js'
import { Link } from 'react-router-dom';
import Tag from './Tag.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import list from '../../css/components/list.module.scss'


const List = () => {
	// 데이터
	const [dataList, setDataList] = useState(data);

	const [direction, setDirection] = useState('');
	const [oldX, setOldX] = useState(0);
	const [oldY, setOldY] = useState(0);

	useEffect(() => {
		AOS.init();
	}, [])
	// 탭
	const [tabActive, setTabActive] = useState(0);
	const tab = [
		{name:'ALL'},
		{name:'Web'},
		{name:'APP'},
		{name:'Hybrid'},
	]
	const tabFilter = (index, name) => {
		setTabActive(index)
		if(name === 'ALL'){
			setDataList(data)
			return false;
		}
		const filtering = data.filter((list) => list.type == name)
		setDataList(filtering)
	}


	function handleMouseMove(e) {//마우스 방향 설정
		if (e.pageX > oldX && e.pageY === oldY) {
			setDirection('east');
		} else if (e.pageX === oldX && e.pageY > oldY) {
			setDirection('south');
		} else if (e.pageX === oldX && e.pageY < oldY) {
			setDirection('north');
		} else if (e.pageX < oldX && e.pageY === oldY) {
			setDirection('west');
		}
		document.body.setAttribute('data-direction', direction);
		setOldX(e.pageX);
		setOldY(e.pageY);
	}

	function stopAndAnimate(obj, property, value, duration) {//애니메이션 재실행
		obj.style.transition = 'none';
		obj.style[property] = value;

		setTimeout(function() {
			obj.style.transition = `${property} ${duration}ms linear`;
			obj.style[property] = '0';
		}, 0);
	}

	function handleMouseEnter(e) { //마우스 enter 애니메이션
		let obj = e.currentTarget.querySelector(`.${list.over}`);
		let direction = document.body.getAttribute('data-direction');

		if (direction === "south") {
			obj.style.left = 0;
			obj.style.top = 0;
			obj.style.right = 0;
			obj.style.bottom = '560px';
			stopAndAnimate(obj, 'bottom', '560px', 200);
		}

		if (direction === "east") {
			obj.style.left = 0;
			obj.style.top = 0;
			obj.style.right = '560px';
			obj.style.bottom = 0;
			stopAndAnimate(obj, 'right', '560px', 200);
		}

		if (direction === "west") {
			obj.style.left = '560px';
			obj.style.top = 0;
			obj.style.right = 0;
			obj.style.bottom = 0;
			stopAndAnimate(obj, 'left', '560px', 200);
		}

		if (direction === "north") {
			obj.style.left = 0;
			obj.style.top = '560px';
			obj.style.right = 0;
			obj.style.bottom = 0;
			stopAndAnimate(obj, 'top', '560px', 200);
		}
	}

	function handleMouseLeave(e) { //마우스 leave 애니메이션
		let obj = e.currentTarget.querySelector(`.${list.over}`);
		let direction = document.body.getAttribute('data-direction');

		if (direction === "south") {
			obj.style.left = 0;
			obj.style.right = 0;
			obj.style.top = 0;
			obj.style.bottom = 0;

			obj.style.transition = 'top 200ms linear';
			obj.style.top = '560px';
		}

		if (direction === "east") {
			obj.style.left = 0;
			obj.style.right = 0;
			obj.style.top = 0;
			obj.style.bottom = 0;

			obj.style.transition = 'left 200ms linear';
			obj.style.left = '560px';
		}

		if (direction === "north") {
			obj.style.left = 0;
			obj.style.right = 0;
			obj.style.top = 0;
			obj.style.bottom = 0;

			obj.style.transition = 'bottom 200ms linear';
			obj.style.bottom = '560px';
		}

		if (direction === "west") {
			obj.style.left = 0;
			obj.style.right = 0;
			obj.style.top = 0;
			obj.style.bottom = 0;

			obj.style.transition = 'right 200ms linear';
			obj.style.right = '560px';
		}
	}

	return (
		<section className={list.pf} onMouseMove={handleMouseMove}>
			<div className='inner'>
				<div className='tit_wrap' data-aos="fade-up" data-aos-duration="1000">
					<h2>Project</h2>
				</div>
				<div className={list.tab} data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
					<ul>
						{tab.map((item, index) => (
							<li key = {index} className={index === tabActive ? list.active :''}>
								<button type="button" onClick={() => tabFilter(index, item.name)}>{item.name}</button>
							</li>
						))}
					</ul>
				</div>
				<div className={list.cards}>
					{dataList.map((item, index) => (
						<div className={list.card} key={index} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-aos="fade-up" data-aos-duration="500" data-aos-delay="300">
							<Link key={index} to={`/detail/${index}`} className={list.card_img}>
								<div className={list.card_inner}>
									<img src={item.url} alt={item.title} />
									<div className={list.over}>상세이동</div>
								</div>
								<Tag tag={item.tag} />
								<h3>{item.title}</h3>
							</Link>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default List;