import React, { useEffect, useState } from 'react';

const Head = () => {
	const [isScrolled, setIsScrolled] = useState(false);

	const scrollToBottom = () => {
		window.scrollTo({
			top: document.documentElement.scrollHeight,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if(scrollPosition > 0 && !isScrolled) {
				setIsScrolled(true);
			}else if (scrollPosition <= 20 && isScrolled) {
				setIsScrolled(false);
			}
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isScrolled])

	return (
		<header className={isScrolled ? 'is_scroll' : ''}>
			<div className='inner'>
				<a href="/" className='name'>MIN HYEOK Portfolio</a>
				{/* <a href="#" className='theme'>
					<div className='theme_text'>
						<span className='theme_text_mask'>
							<span>Dark</span>
							<span>Light</span>
						</span>
						&nbsp;Mode
					</div>
					<div className='theme_icon'>
						<span className='theme_icon_sun'>
							<span></span>
						</span>
						<span className='theme_icon_moon'></span>
					</div>
				</a> */}
			</div>
		</header>
	);
};

export default Head;