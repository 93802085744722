import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import AOS from 'aos';
import 'aos/dist/aos.css';
import edit from '../../css/components/edit.module.scss'

const Edit = () => {
	const [fs, setFontSize] = useState('32')
	const [ls, setLetterSpacing] = useState('0')
	const [lh, setLineHeight] = useState('40')
	const form = useRef();
	useEffect(() => {
		AOS.init();
	}, [])
	const sendEmail = e => {
		e.preventDefault();

		emailjs.sendForm("service_y5yyu5u", "template_u7simre", form.current, "OiQv6dnH23P-Rboey").then(
			result => {
				alert("성공적으로 이메일이 전송되었습니다.");
				form.current.reset();
			},
			error => {
				console.log(error.text);
				alert("이메일이 전송이 실패되었습니다.");
			},
		);
	};
	return (
		<section className={edit.edit} id='edit'>
			<div className="inner">
				<div className='tit_wrap' data-aos="fade-up" data-aos-duration="1000">
					<h2>Contact</h2>
				</div>
				<form ref={form} onSubmit={sendEmail}>
					<div className={edit.form} data-aos="fade-up" data-aos-duration="1000">
						<div className={edit.layout}>
							<h3>제목</h3>
							<input name="ask_tit" placeholder='문의 제목' className={edit.inpt}/>
						</div>
						<div className={edit.layout}>
							<h3>이메일</h3>
							<input name="user_email" placeholder='이메일' className={edit.inpt}/>
						</div>
					</div>
					<h3 data-aos="fade-up" data-aos-duration="1000">문의 내용</h3>
					<div className={edit.opt} data-aos="fade-up" data-aos-duration="1000">
						<div className={edit.inpt_wrap}>
							<p>
								<span>Size</span>
								<strong id="fz_txt">{fs}</strong>px
							</p>
							<input type="range" id="fs_value" min="15" max="50" value={fs} onChange={e => setFontSize(e.target.value)}/>
						</div>
						<div className={edit.inpt_wrap}>
							<p>
								<span>Letter Spacing</span>
								<strong id="letter_txt">{ls}</strong>px
							</p>
							<input type="range" id="letter_value" min="-4" max="4" value={ls} onChange={e => setLetterSpacing(e.target.value)}/>
						</div>
						<div className={edit.inpt_wrap}>
							<p>
								<span>line Height</span>
								<strong id="line_txt">{lh}</strong>px
							</p>
							<input type="range" id="line_value" min="20" max="60" value={lh} onChange={e => setLineHeight(e.target.value)}/>
						</div>
					</div>
					<div className="inpt_warp" data-aos="fade-up" data-aos-duration="1000">
						<div className={edit.txt_area}>
							<textarea rows="" cols="" name='message' style={{fontSize:fs+'px', letterSpacing:ls+'px', lineHeight:lh+'px'}} id="txt_edit" defaultValue={'내용을 입력해 주세요'}/>
						</div>
					</div>
					<input type="submit" value="메일 전송" className={edit.btn_sand} data-aos="fade-up" data-aos-duration="650"/>
				</form>
			</div>
		</section>
	);
};

export default Edit;