export default[
	// mhcho.com / whalsgur0405 /
	{
		id:30,
		type:'Web',
		tag:[
			'PC',
			'여행'
		],
		url : "/img/logo/sk_logo_02.png",
		title: 'SK 법인 렌터카 웹 구축',
		point: '#3F4970',
		date : '2024.01 ~ 2024.10',
		contribution : '80%',
		infoList : [
			'법인 렌터카 서비스(계약, 사고 조회 페이지) UI 개발',
			'React 기반으로 고객의 사용성을 고려한 UI 퍼블리싱',
			'Styled Components로 컴포넌트 스타일링',
			'시멘틱 태그, 메타 태그, 웹 접근성을 맞춰 SEO 최적화 작업',
		],
		intro: '/img/intro/sk_intro.png',
	},
	{
		id:0,
		type:'Hybrid',
		tag:[
			'적응형',
			'여행'
		],
		url : "/img/logo/sk_logo.png",
		title: 'SK렌터카 / 빌리카 구축 및 운영',
		point: '#fa4e29',
		date : '2023.03 ~ 2023.12',
		contribution : '50%',
		infoList : [
			'SK렌터카와 빌리카 여행 예약 홈페이지',
			'React 환경에서 호환이 기반이 되도록 웹 적응형 UI 개발 고도화 및 운영',
			'여행 예약 플랫폼에서 필요한 기능인 예약, 결제, 예약 변경, 취소 등의 여행 프로세스 UI 개발',
			'영문 사용자를 대상으로 하는 예약 사이트의 UI를 구축',
			'Usability Test 진행 후 피로감을 최소화 하는 UI/UX로 개선하여 예약결제 평균 시간을 1분 43초대로 단축 및 웹 어워드 모바일웹 최고 대상 수상',
			'Usability Test의 개선점 중 하나인 예약 패널 스크롤 UI관련 문제를 해결하기 위해 overflow: overlay 속성의 사용 불가로 인한 대안으로 mCustomScrollbar 플러그인을 도입하여 리액트 프로젝트에 효과적으로 적용'
		],
		intro: '/img/intro/sk_intro.png',
		mo_slide : [
			'/img/slide/slide_sk_mo_01.png',
			'/img/slide/slide_sk_mo_02.png',
			'/img/slide/slide_sk_mo_03.png',
		],
		pc_slide : [
			'/img/slide/slide_sk_pc_01.png',
			'/img/slide/slide_sk_pc_02.png',
			'/img/slide/slide_sk_pc_03.png',
		],
		LiveLink : 'https://homepage.skcarrental.com/',
	},
	// {
	// 	id:17,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'관리자',
	// 	],
	// 	url : "/img/logo/eWave_logo.png",
	// 	title: 'eWave (Admin)',
	// 	point : '#0071B7',
	// 	date : '2023.03 ~ 2023.06',
	// 	contribution : '80%',
	// 	infoList : [
	// 		'장비 모니터링 서비스 업체 이웨이브솔루션의 대시보드(관리자) 페이지 구축',
	// 		'다양한 차트 플러그인(chart.js, Apache Echarts, ApexCharts.js)을 적용',
	// 	],
	// 	intro: '/img/intro/eWave_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_eWave_mo_01.png',
	// 		'/img/slide/slide_eWave_mo_02.png',
	// 		'/img/slide/slide_eWave_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_eWave_pc_01.png',
	// 		'/img/slide/slide_eWave_pc_02.png',
	// 		'/img/slide/slide_eWave_pc_03.png',
	// 	],
	// 	// indexHTML : 'https://minhyeokcho.github.io/K_ewave/list.html'
	// },
	{
		id:1,
		type:'Hybrid',
		tag:[
			'적응형',
			'멤버쉽'
		],
		url : "/img/logo/ediya_logo.png",
		title: '이디야 멤버스',
		point : '#2141A0',
		date : '2022.09 ~ 2023.03',
		contribution : '70%',
		infoList : [
			'PL로 참여하여 웹 표준 기반한 가이드 작성',
			'PC 반응형 Web & Mobile App내 Web View영역 작업',
			'input[type=“range”]를 사용하여 별점 드래그 되는 모션 구현 및 상품 리뷰 페이지 UI 개발',
			'협업 및 파일 관리를 위해 index 페이지 작성과 현재 진행률 표시',
			'멤버십 웹/앱 오픈 이후 신규 회원 수 130% 증가 및 스마트 앱 어워드 프랜차이즈분야 대상 수상'
		],
		intro: '/img/intro/ediya_intro.png',
		mo_slide : [
			'/img/slide/slide_ediya_mo_01.png',
			'/img/slide/slide_ediya_mo_02.png',
			'/img/slide/slide_ediya_mo_03.png',
		],
		pc_slide : [
			'/img/slide/slide_ediya_pc_01.png',
			'/img/slide/slide_ediya_pc_02.png',
			'/img/slide/slide_ediya_pc_03.png',
		],
		LiveLink : 'https://members.ediya.com/'
	},
	{
		id:2,
		type:'Hybrid',
		tag:[
			'적응형',
			'커머스'
		],
		url : "/img/logo/chk_logo.png",
		title: '종근당건강몰',
		point : '#2360E6',
		date : '2022.04 ~ 2022.08',
		contribution : '25%',
		infoList : [
			'커머스 플랫폼에서 회원, 비회원 별 주문, 결제, 취소, 교환, 반품, 배송 조회 등의 프로세스에 대하여 UI 개발',
			'복권 스크래치 이벤트 구현 (wScratchPad.js 플러그인 사용하여 복권이 긁히는 모션 제시)',
			'룰렛 콘텐츠 구현 (TweenMax를 사용하여 모션을 구현하고 원하는 값이 당첨되도록 UI 개발)',
			'모바일 작업시 CSS 단위를 ‘vw’로 선택하여 작업 후, 갤럭시 폴드에서 컨첸츠가 화면 넓이 기준으로 계속 늘어나는 이슈가 발생하여 ‘px’로 전체 변경',
			'리뉴얼 구축을 통해 베스트, 기획전 등 이벤트 콘텐츠 제공 및 주문,결제 UI 간소화로 매출 36배 상승',
		],
		intro: '/img/intro/chk_intro.PNG',
		mo_slide : [
			'/img/slide/slide_chk_mo_01.png',
			'/img/slide/slide_chk_mo_02.png',
			'/img/slide/slide_chk_mo_03.png',
		],
		pc_slide : [
			'/img/slide/slide_chk_pc_01.png',
			'/img/slide/slide_chk_pc_02.png',
			'/img/slide/slide_chk_pc_03.png',
		],
		LiveLink : 'https://ckdhcmall.co.kr/prdExhibitView.do?idx=116&utm_source=google_sa&utm_medium=paid_all&utm_campaign=%EA%B8%B0%ED%83%80_%EC%9E%90%EC%82%AC&utm_content=%EA%B8%B0%ED%83%80_%EC%A2%85%EA%B7%BC%EB%8B%B9&utm_term=%EC%A2%85%EA%B7%BC%EB%8B%B9%EA%B1%B4%EA%B0%95%EB%AA%B0&gad_source=1&gclid=CjwKCAiAjfyqBhAsEiwA-UdzJA6yFKQwvsOh98OCl24zM4XcpnFn_jkGCSOoZlTvjUPu5uOZNeuyvRoCATwQAvD_BwE'
	},
	{
		id:3,
		type:'APP',
		tag:[
			'모바일',
			'커머스'
		],
		url : "/img/logo/cu_logo.png",
		title: 'CU 멤버십',
		point : '#37d243',
		date : '2021.09 ~ 2022.04',
		contribution : '20%',
		infoList : [
			'IOS의 노치 대응(env, constant 속성) 및 다양한 모바일 기기에서의 최적화를 보장하는 UI 개발',
			'랜딩페이지 스크롤 이벤트를 디바이스 높이에 따른 텍스트와 이미지 겹침 현상과 노치 영역을 고려하여 작업',
			'한 눈에 파악할 수 있는 UI/UX 구성으로 스마트 앱 어워드 최고 대상 수상'
		],
		intro: '/img/intro/cu_intro.png',
		mo_slide : [
			'/img/slide/slide_cu_mo_01.png',
			'/img/slide/slide_cu_mo_02.png',
			'/img/slide/slide_cu_mo_03.png',
		],
	},
	{
		id:4,
		type:'Web',
		tag:[
			'PC',
			'관리자'
		],
		url : "/img/logo/hm_logo.png",
		title: 'HMGICS 제조플랫폼',
		point : '#2360E6',
		date : '2021.09 ~ 2022.11',
		contribution : '60%',
		infoList : [
			'Vue.js기반으로 SFaaS 컴포넌트를 활용하여 제조플랫폼 컴포넌트 작업 수행',
			'생산/물류/품질/판매 운영 등 여러 분과의 로그인 및 메인 대시보드 페이지 구축',
			'Sass의 변수를 활용하여 각 분과별 키 색상을 효율적으로 관리'
		],
		intro: '/img/intro/hm_intro.png',
	},
	// {
	// 	id:5,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'브랜드'
	// 	],
	// 	url : "/img/logo/law_logo.png",
	// 	title: '법무법인 에스',
	// 	point : '#97755a',
	// 	date : '2023.03 ~ 2023.04',
	// 	contribution : '80%',
	// 	infoList : [
	// 		'로펌 법무법인 에스의 기업 소개 홈페이지',
	// 		'소개, 신청, 게시판 등 소개 페이지 퍼블리싱 작업',
	// 	],
	// 	intro: '/img/intro/law_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_law_mo_01.png',
	// 		'/img/slide/slide_law_mo_02.png',
	// 		'/img/slide/slide_law_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_law_pc_01.png',
	// 		'/img/slide/slide_law_pc_02.png',
	// 		'/img/slide/slide_law_pc_03.png',
	// 	],
	// 	LiveLink : 'http://lawthes.com/',
	// 	indexHTML : 'https://minhyeokcho.github.io/K_lawthes/list.html',

	// },
	// {
	//	 id:6,
	//	 type:'Web',
	//	 tag:[
	//		 '반응형',
	//		 '브랜드'
	//	 ],
	//	 url : "/img/logo/ic_logo.png",
	//	 title: '인천김포고속도로(주)',
	//	 point : '#146f50',
	//	 date : '2023.01 ~ 2023.02',
	//	 contribution : '100%',
	//	 infoList : [
	//		 '예약-대여-반납 전과정 온라인 내재화하여 SK렌터카만의 경험을 제공.',
	//	 ],
	//	 intro: '/img/intro/ic_intro.png',
	//	 mo_slide : [
	//		 '/img/slide/slide_ic_mo_01.png',
	//	 ],
	//	 pc_slide : [
	//		 '/img/slide/slide_ic_pc_01.png',
	//	 ],
	//	 LiveLink : 'http://www.igex.co.kr/',
	// },
	// {
	//	 id:7,
	//	 type:'Web',
	//	 tag:[
	//		 '반응형',
	//		 '브랜드'
	//	 ],
	//	 url : "/img/logo/lime_logo.png",
	//	 title: '라임에듀',
	//	 point : '#43D32B',
	//	 date : '2023.02 ~ 2023.03',
	//	 contribution : '100%',
	//	 infoList : [
	//		 '예약-대여-반납 전과정 온라인 내재화하여 SK렌터카만의 경험을 제공.',
	//	 ],
	//	 intro: '/img/intro/lime_intro.png',
	//	 mo_slide : [
	//		 '/img/slide/slide_lime_mo_01.png',
	//		 '/img/slide/slide_lime_mo_02.png',
	//		 '/img/slide/slide_lime_mo_03.png',
	//	 ],
	//	 pc_slide : [
	//		 '/img/slide/slide_lime_pc_01.png',
	//		 '/img/slide/slide_lime_pc_02.png',
	//		 '/img/slide/slide_lime_pc_03.png',
	//	 ],
	//	 LiveLink : 'https://minhyeokcho.github.io/K_lime/main.html',
	//	 indexHTML : 'https://minhyeokcho.github.io/K_lime/sub/status.html'
	// },
	// {
	// 	id:8,
	// 	type:'APP',
	// 	tag:[
	// 		'모바일',
	// 		'게임'
	// 	],
	// 	url : "/img/logo/chipster_logo.png",
	// 	title: 'Chipster',
	// 	point : '#28AA7F',
	// 	date : '2023.04 ~ 2023.05',
	// 	contribution : '80%',
	// 	infoList : [
	// 		'여행 콘텐츠를 기반으로 하는 게임 APP 칩스터',
	// 	],
	// 	intro: '/img/intro/chipster_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_chipster_mo_01.png',
	// 		'/img/slide/slide_chipster_mo_02.png',
	// 		'/img/slide/slide_chipster_mo_03.png',
	// 	],
	// 	indexHTML : 'https://minhyeokcho.github.io/K_chipster/list.html'
	// },
	// {
	// 	id:9,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'브랜드'
	// 	],
	// 	url : "/img/logo/onemount_logo.png",
	// 	title: '원마운트',
	// 	point : '#009ED0',
	// 	date : '2023.04 ~ 2023.06',
	// 	contribution : '80%',
	// 	infoList : [
	// 		'일산 원마운트 소개 홈페이지',
	// 	],
	// 	intro: '/img/intro/onemount_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_onemount_mo_01.png',
	// 		'/img/slide/slide_onemount_mo_02.png',
	// 		'/img/slide/slide_onemount_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_onemount_pc_01.png',
	// 		'/img/slide/slide_onemount_pc_02.png',
	// 		'/img/slide/slide_onemount_pc_03.png',
	// 	],
	// 	indexHTML : 'https://minhyeokcho.github.io/K_onemount/list.html'
	// },
	// {
	// 	id:10,
	// 	type:'APP',
	// 	tag:[
	// 		'모바일',
	// 		'솔루션'
	// 	],
	// 	url : "/img/logo/sizemine_logo.png",
	// 	title: 'Size Mine',
	// 	point : '#D01743',
	// 	date : '2023.05 ~ 2023.06',
	// 	contribution : '70%',
	// 	infoList : [
	// 		'신체 사이즈 측정 서비스를 제공하는 사이즈마인의 모바일 버전 솔루션',
	// 	],
	// 	intro: '/img/intro/sizemine_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_sizemine_mo_01.png',
	// 		'/img/slide/slide_sizemine_mo_02.png',
	// 		'/img/slide/slide_sizemine_mo_03.png',
	// 	],
	// 	indexHTML : 'https://minhyeokcho.github.io/K_sizemine/list.html'
	// },
	// {
	// 	id:11,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'브랜드'
	// 	],
	// 	url : "/img/logo/dermaject_logo.png",
	// 	title: 'Dermaject',
	// 	point : '#253572',
	// 	date : '2023.05 ~ 2023.06',
	// 	contribution : '100%',
	// 	infoList : [
	// 		'뷰티 업체 더마젝의 기업 소개 홈페이지',
	// 	],
	// 	intro: '/img/intro/dermaject_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_dermaject_mo_01.png',
	// 		'/img/slide/slide_dermaject_mo_02.png',
	// 		'/img/slide/slide_dermaject_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_dermaject_pc_01.png',
	// 		'/img/slide/slide_dermaject_pc_02.png',
	// 		'/img/slide/slide_dermaject_pc_03.png',
	// 	],
	// 	LiveLink : 'https://www.dermaject.com/'
	// },
	// {
	// 	id:12,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'브랜드',
	// 		'FullPage',
	// 	],
	// 	url : "/img/logo/buildmusic_logo.png",
	// 	title: 'BUILDMUSIC',
	// 	point : '#000',
	// 	date : '2023.06 ~ 2023.07',
	// 	contribution : '70%',
	// 	infoList : [
	// 		'뮤지션 투자 기업 빌드업뮤직의 기업 소개 홈페이지',
	// 		'Fullpage 플러그인을 사용하여 작업'
	// 	],
	// 	intro: '/img/intro/buildmusic_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_buildmusic_mo_01.png',
	// 		'/img/slide/slide_buildmusic_mo_02.png',
	// 		'/img/slide/slide_buildmusic_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_buildmusic_pc_01.png',
	// 		'/img/slide/slide_buildmusic_pc_02.png',
	// 		'/img/slide/slide_buildmusic_pc_03.png',
	// 	],
	// 	LiveLink : 'http://buildmusic.kr/'
	// },
	// {
	// 	id:13,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'브랜드',
	// 	],
	// 	url : "/img/logo/geo_logo.png",
	// 	title: '지오이엔지',
	// 	point : '#00ADEF',
	// 	date : '2023.06 ~ 2023.07',
	// 	contribution : '70%',
	// 	infoList : [
	// 		'조경 및 도시 설계 기업 (주)지오이엔지의 기업 소개 홈페이지',
	// 	],
	// 	intro: '/img/intro/geo_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_geo_mo_01.png',
	// 		'/img/slide/slide_geo_mo_02.png',
	// 		'/img/slide/slide_geo_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_geo_pc_01.png',
	// 		'/img/slide/slide_geo_pc_02.png',
	// 		'/img/slide/slide_geo_pc_03.png',
	// 	],
	// 	LiveLink : 'http://goeng.hpnamooya.gethompy.com/index.php'
	// },
	// {
	// 	id:14,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'브랜드',
	// 	],
	// 	url : "/img/logo/oneul_logo.png",
	// 	title: '(주)오늘',
	// 	point : '#319400',
	// 	date : '2023.06 ~ 2023.07',
	// 	contribution : '80%',
	// 	infoList : [
	// 		'새송이버섯 생산 업체 (주)오늘의 기업 소개 홈페이지',
	// 	],
	// 	intro: '/img/intro/oneul_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_oneul_mo_01.png',
	// 		'/img/slide/slide_oneul_mo_02.png',
	// 		'/img/slide/slide_oneul_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_oneul_pc_01.png',
	// 		'/img/slide/slide_oneul_pc_02.png',
	// 		'/img/slide/slide_oneul_pc_03.png',
	// 	],
	// 	LiveLink : 'http://today.hpnamooya.gethompy.com/'
	// },
	// {
	// 	id:15,
	// 	type:'Web',
	// 	tag:[
	// 		'PC',
	// 		'브랜드',
	// 	],
	// 	url : "/img/logo/classE_logo.png",
	// 	title: 'ClassE',
	// 	point : '#0CDFF6',
	// 	date : '2023.08 ~ 2023.09',
	// 	contribution : '70%',
	// 	infoList : [
	// 		'방과 후 e스포츠 교육 프로그램 ClassE의 기업 소개 홈페이지',
	// 	],
	// 	intro: '/img/intro/classE_intro.png',
	// 	pc_slide : [
	// 		'/img/slide/slide_classE_pc_01.png',
	// 		'/img/slide/slide_classE_pc_02.png',
	// 	],
	// 	indexHTML : 'https://minhyeokcho.github.io/K_classE/list.html'
	// },
	// {
	// 	id:16,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'브랜드',
	// 		'FullPage',
	// 	],
	// 	url : "/img/logo/bosang_logo.png",
	// 	title: '노무법인 더보상',
	// 	point : '#3D894E',
	// 	date : '2023.09 ~ 2023.10',
	// 	contribution : '80%',
	// 	infoList : [
	// 		'노무법인 더보상의 기업 소개 홈페이지',
	// 	],
	// 	intro: '/img/intro/bosang_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_bosang_mo_01.png',
	// 		'/img/slide/slide_bosang_mo_02.png',
	// 		'/img/slide/slide_bosang_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_bosang_pc_01.png',
	// 		'/img/slide/slide_bosang_pc_02.png',
	// 		'/img/slide/slide_bosang_pc_03.png',
	// 	],
	// 	indexHTML : 'https://minhyeokcho.github.io/K_bosang/list.html'
	// },
	// {
	// 	id:18,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'예약',
	// 	],
	// 	url : "/img/logo/pop_logo.png",
	// 	title: 'play on the pitch(POP)',
	// 	point : '#00A580',
	// 	date : '2023.11 ~ 2023.12',
	// 	contribution : '90%',
	// 	infoList : [
	// 		'play on the pitch의 풋살장 예약 및 소개 페이지',
	// 	],
	// 	intro: '/img/intro/pop_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_pop_mo_01.png',
	// 		'/img/slide/slide_pop_mo_02.png',
	// 		'/img/slide/slide_pop_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_pop_pc_01.png',
	// 		'/img/slide/slide_pop_pc_02.png',
	// 		'/img/slide/slide_pop_pc_03.png',
	// 	],
	// 	indexHTML : 'https://minhyeokcho.github.io/K_pop/list.html'
	// },
	// {
	// 	id:27,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'도서관',
	// 	],
	// 	url : "/img/logo/tu_logo.png",
	// 	title: '태국 타마삿 대학교 도서관',
	// 	point : '#C00832',
	// 	date : '2021.07 ~ 2021.08',
	// 	contribution : '100%',
	// 	infoList : [
	// 		'태국 타마삿 대학교 도서관 검색 홈페이지',
	// 		'메인 및 서브페이지 등 사이트 전반의 퍼블리싱 작업을 진행'
	// 	],
	// 	intro: '/img/intro/tu_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_tu_mo_01.png',
	// 		'/img/slide/slide_tu_mo_02.png',
	// 		'/img/slide/slide_tu_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_tu_pc_01.png',
	// 		'/img/slide/slide_tu_pc_02.png',
	// 		'/img/slide/slide_tu_pc_03.png',
	// 	],
	// 	LiveLink : 'https://library.tu.ac.th/'
	// },
	{
		id:23,
		type:'Web',
		tag:[
			'반응형',
			'도서관',
		],
		url : "/img/logo/nddl_m1_logo.png",
		title: '국방대학교 도서관',
		point : '#56a105',
		date : '2021.01 ~ 2021.06',
		contribution : '100%',
		infoList : [
			'템플릿 형태 작업으로 국방부 70여개 군별 전자도서관 효율적으로 관리',
			'퓨쳐누리 내부 도서관 검색 솔루션(통합검색, 좌석예약, 추천도서 등) 신규 구축',
		],
		intro: '/img/intro/nddl_m1_intro.png',
		mo_slide : [
			'/img/slide/slide_nddl_m1_mo_01.png',
			'/img/slide/slide_nddl_m1_mo_02.png',
			'/img/slide/slide_nddl_m1_mo_03.png',
		],
		pc_slide : [
			'/img/slide/slide_nddl_m1_pc_01.png',
			'/img/slide/slide_nddl_m1_pc_02.png',
			'/img/slide/slide_nddl_m1_pc_03.png',
		],
		LiveLink : 'https://nddl.mil.kr/M1/index/'
	},
	{
		id:22,
		type:'Web',
		tag:[
			'반응형',
			'도서관',
			'웹접근성',
		],
		url : "/img/logo/jbnu_logo.png",
		title: '전북대학교 도서관',
		point : '#a52869',
		date : '2020.11 ~ 2021.03',
		contribution : '100%',
		infoList : [
			'전북대학교 도서관 검색 홈페이지',
			'웹 표준 및 크로스브라우징에 기반하여 다양한 클릭 인터렉션 제시 및 구축',
			'국/영문 웹 접근성 마크 획득 및 반응형 퍼블리싱'
		],
		intro: '/img/intro/jbnu_intro.png',
		mo_slide : [
			'/img/slide/slide_jbnu_mo_01.png',
			'/img/slide/slide_jbnu_mo_02.png',
			'/img/slide/slide_jbnu_mo_03.png',
		],
		pc_slide : [
			'/img/slide/slide_jbnu_pc_01.png',
			'/img/slide/slide_jbnu_pc_02.png',
			'/img/slide/slide_jbnu_pc_03.png',
		],
		LiveLink : 'https://dl.jbnu.ac.kr/'
	},
	// {
	// 	id:21,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'도서관',
	// 	],
	// 	url : "/img/logo/kmou_logo.png",
	// 	title: '한국해양대학교 도서관',
	// 	point : '#114470',
	// 	date : '2020.11 ~ 2021.01',
	// 	contribution : '100%',
	// 	infoList : [
	// 		'한국해양대학교 도서관 검색 홈페이지',
	// 		'국/영문 페이지 작업.'
	// 	],
	// 	intro: '/img/intro/kmou_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_kmou_mo_01.png',
	// 		'/img/slide/slide_kmou_mo_02.png',
	// 		'/img/slide/slide_kmou_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_kmou_pc_01.png',
	// 		'/img/slide/slide_kmou_pc_02.png',
	// 		'/img/slide/slide_kmou_pc_03.png',
	// 	],
	// 	LiveLink : 'https://library.kmou.ac.kr/'
	// },
	// {
	// 	id:20,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'도서관',
	// 	],
	// 	url : "/img/logo/kmu_logo.png",
	// 	title: '계명대학교 도서관',
	// 	point : '#0358AC',
	// 	date : '2020.10 ~ 2020.11',
	// 	contribution : '100%',
	// 	infoList : [
	// 		'계명대학교 도서관 검색 홈페이지',
	// 	],
	// 	intro: '/img/intro/kmu_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_kmu_mo_01.png',
	// 		'/img/slide/slide_kmu_mo_02.png',
	// 		'/img/slide/slide_kmu_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_kmu_pc_01.png',
	// 		'/img/slide/slide_kmu_pc_02.png',
	// 		'/img/slide/slide_kmu_pc_03.png',
	// 	],
	// 	LiveLink : 'https://library.kmu.ac.kr/'
	// },
	{
		id:19,
		type:'Web',
		tag:[
			'반응형',
			'도서관',
			'Fullpage',
		],
		url : "/img/logo/cnu_logo.png",
		title: '충남대학교 도서관',
		point : '#014099',
		date : '2020.09 ~ 2020.11',
		contribution : '100%',
		infoList : [
			'충남대학교 도서관 검색 홈페이지',
			'Fullpage 플러그인을 사용하여 작업.',
			'국/영문 페이지 작업.'
		],
		intro: '/img/intro/cnu_intro.png',
		mo_slide : [
			'/img/slide/slide_cnu_mo_01.png',
			'/img/slide/slide_cnu_mo_02.png',
			'/img/slide/slide_cnu_mo_03.png',
		],
		pc_slide : [
			'/img/slide/slide_cnu_pc_01.png',
			'/img/slide/slide_cnu_pc_02.png',
			'/img/slide/slide_cnu_pc_03.png',
		],
		LiveLink : 'https://library.cnu.ac.kr/'
	},
	// {
	// 	id:24,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'도서관',
	// 	],
	// 	url : "/img/logo/nddl_ad_logo.png",
	// 	title: '육군사관학교 학술정보원',
	// 	point : '#5A9F05',
	// 	date : '2021.01 ~ 2021.06',
	// 	contribution : '100%',
	// 	infoList : [
	// 		'템플릿 형태 작업으로 국방부 70여개 군별 전자도서관 효율적으로 관리',
	// 		'퓨쳐누리 내부 도서관 검색 솔루션(통합검색, 좌석예약, 추천도서 등) 신규 구축',
	// 	],
	// 	intro: '/img/intro/nddl_ad_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_nddl_ad_mo_01.png',
	// 		'/img/slide/slide_nddl_ad_mo_02.png',
	// 		'/img/slide/slide_nddl_ad_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_nddl_ad_pc_01.png',
	// 		'/img/slide/slide_nddl_ad_pc_02.png',
	// 		'/img/slide/slide_nddl_ad_pc_03.png',
	// 	],
	// 	LiveLink : 'https://nddl.mil.kr/AD/index/'
	// },
	// {
	// 	id:25,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'도서관',
	// 	],
	// 	url : "/img/logo/nddl_f2_logo.png",
	// 	title: '공군사관학교 학술정보원',
	// 	point : '#0D64B4',
	// 	date : '2021.01 ~ 2021.06',
	// 	contribution : '100%',
	// 	infoList : [
	// 		'템플릿 형태 작업으로 국방부 70여개 군별 전자도서관 효율적으로 관리',
	// 		'퓨쳐누리 내부 도서관 검색 솔루션(통합검색, 좌석예약, 추천도서 등) 신규 구축',
	// 	],
	// 	intro: '/img/intro/nddl_f2_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_nddl_f2_mo_01.png',
	// 		'/img/slide/slide_nddl_f2_mo_02.png',
	// 		'/img/slide/slide_nddl_f2_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_nddl_f2_pc_01.png',
	// 		'/img/slide/slide_nddl_f2_pc_02.png',
	// 		'/img/slide/slide_nddl_f2_pc_03.png',
	// 	],
	// 	LiveLink : 'https://nddl.mil.kr/F2/index/'
	// },
	// {
	// 	id:26,
	// 	type:'Web',
	// 	tag:[
	// 		'반응형',
	// 		'도서관',
	// 	],
	// 	url : "/img/logo/nddl_n8_logo.png",
	// 	title: '해군사관학교 학술정보원',
	// 	point : '#334ABD',
	// 	date : '2021.01 ~ 2021.06',
	// 	contribution : '100%',
	// 	infoList : [
	// 		'템플릿 형태 작업으로 국방부 70여개 군별 전자도서관 효율적으로 관리',
	// 		'퓨쳐누리 내부 도서관 검색 솔루션(통합검색, 좌석예약, 추천도서 등) 신규 구축',
	// 	],
	// 	intro: '/img/intro/nddl_n8_intro.png',
	// 	mo_slide : [
	// 		'/img/slide/slide_nddl_n8_mo_01.png',
	// 		'/img/slide/slide_nddl_n8_mo_02.png',
	// 		'/img/slide/slide_nddl_n8_mo_03.png',
	// 	],
	// 	pc_slide : [
	// 		'/img/slide/slide_nddl_n8_pc_01.png',
	// 		'/img/slide/slide_nddl_n8_pc_02.png',
	// 		'/img/slide/slide_nddl_n8_pc_03.png',
	// 	],
	// 	LiveLink : 'https://nddl.mil.kr/N8/index/'
	// },

]