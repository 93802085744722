import React from 'react';

const Footer = () => {
	return (
		<footer>
			<div className='inner'>
				<span className='name'>ChoMinHyeok</span>
				<div className='bar'></div>
				<p className='copy'>Made By 2024</p>
			</div>
		</footer>
	);
};

export default Footer;