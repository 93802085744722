import React, { useCallback, useRef, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import sub from '../css/components/sub.module.scss'

const FramePC = ({pcSlideImg}) => {
	useEffect(() => {
		AOS.init();
	}, [])

	const settings = {
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true,
		arrows : false,
		autoplay: true,
		autoplaySpeed: 5000,
	}

	// slick arrow
	const pcSlickRef = useRef()
	const pcPrev = useCallback(() => pcSlickRef.current.slickPrev(),[])
	const pcNext = useCallback(() => pcSlickRef.current.slickNext(),[])

	return (
		<div className={sub.pf_sec} data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
			<div className={[sub.frame, sub.frame_pc].join(" ")}>
				<figure className={sub.frame_img}>
					<img src="/img/frame_pc.png" alt="PC FRAME" />
				</figure>
				<div className={sub.frame_inner}>
					<div className={sub.scroll_area}>
						<Slider {...settings} ref={pcSlickRef} className={sub.pf_slide}>
							{pcSlideImg.map((item, index) =>(
								<div key={index}>
									<img src={item} alt={index+1+'번째 이미지'} />
								</div>
							))}
						</Slider>
					</div>
				</div>

				{/* slick arrow */}
				<div className={sub.slick_arrow}>
					<button className={sub.prev} onClick={pcPrev}>이전</button>
					<button className={sub.next} onClick={pcNext}>다음</button>
				</div>
				{/*// slick arrorw */}
			</div>
		</div>
	);
};

export default FramePC;