import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import sub from '../css/components/sub.module.scss'

const LiveBtn = ({colorCode, liveHTML}) => {
	useEffect(() => {
		AOS.init();
	}, [])

	return (
		<a href={liveHTML} className={sub.btn} style={{borderColor:colorCode}} target='_blank' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<i style={{backgroundColor:colorCode}}></i>
			<span>바로가기</span>
		</a>
	);
};

export default LiveBtn;