import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../../css/components/book.scss'

const Book = () => {
	useEffect(() => {
		AOS.init();
	}, [])

	const next01 = () => {
		document.querySelector('.cnt1').classList.add('turn')
		document.querySelector('.cnt1').style.zIndex = '3'
		document.querySelector('.cnt1').style.transition = 'transform 1s ease-in-out, z-index 0.1s 0.6s ease-in-out'
		document.querySelector('.book_area').classList.remove('first_cover')
		document.querySelector('.pages').classList.add('open')
	}
	const next02 = () => {
		document.querySelector('.cnt2').classList.add('turn')
		document.querySelector('.cnt2').style.zIndex = '4'
	}
	const next03 = () => {
		document.querySelector('.cnt3').classList.add('turn')
		document.querySelector('.cnt3').style.zIndex = '5'
		document.querySelector('.cnt3').style.transition = 'transform 1s ease-in-out, z-index 0.1s 0.4s ease-in-out'
		document.querySelector('.book_area').classList.add('last_cover')
		document.querySelector('.pages').classList.remove('open')

	}
	const prev01 = () => {
		document.querySelector('.cnt1').classList.remove('turn')
		document.querySelector('.cnt1').style.zIndex = '5'
		document.querySelector('.cnt1').style.transition = 'transform 1s ease-in-out, z-index 0.1s 0.4s ease-in-out'
		document.querySelector('.book_area').classList.add('first_cover')
		document.querySelector('.pages').classList.remove('open')

	}
	const prev02 = () => {
		document.querySelector('.cnt2').classList.remove('turn')
		document.querySelector('.cnt2').style.zIndex = '4'
	}
	const prev03 = () => {
		document.querySelector('.cnt3').classList.remove('turn')
		document.querySelector('.cnt3').style.zIndex = '3'
		document.querySelector('.cnt3').style.transition = 'transform 1s ease-in-out, z-index 0.1s 0.6s ease-in-out'
		document.querySelector('.book_area').classList.remove('last_cover')
		document.querySelector('.pages').classList.add('open')
	}
	const home = () => {
		document.querySelector('.cnt1').classList.remove('turn')
		document.querySelector('.cnt2').classList.remove('turn')
		document.querySelector('.cnt3').classList.remove('turn')
		document.querySelector('.cnt1').style.zIndex = '5'
		document.querySelector('.cnt2').style.zIndex = '4'
		document.querySelector('.cnt3').style.zIndex = '3'
		document.querySelector('.cnt1').style.transition = 'transform 1s ease-in-out, z-index 0.1s 0.4s ease-in-out'
		document.querySelector('.book_area').classList.add('first_cover')
		document.querySelector('.book_area').classList.remove('last_cover')
		document.querySelector('.pages').classList.remove('open')
	}
	return (
		<section className="book">
			<div className='inner'>
				<div className='tit_wrap' data-aos="fade-up" data-aos-duration="1000">
					<h2>Experience</h2>
				</div>
				<div className='pages' data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
					<div className='book_area first_cover'>
						<div className='page_wrap cnt1'>
							<div className='pages_page page_01 front'>
								<div className='pages_page__inner'>
									<h3>CAREER <br/>INSIGHTS<br/>BOOK!</h3>

									{/* 시작버튼 */}
									<button type="button" className='start_book' onClick={next01}>
										<span>Let's Read !</span>
										<svg width="16px" height="12px" viewBox="0 0 13 10">
											<path d="M1,5 L11,5"></path>
											<polyline points="8 1 12 5 8 9"></polyline>
										</svg>
									</button>
									{/*// 시작버튼 */}

									<div className='control_btn next first_next'>
										<button className='next_01' onClick={next01}>다음</button>
									</div>
								</div>
							</div>
							<div className='pages_page page_02 back'>
								<div className='pages_page__inner'>
									<p className='logo logo_w'>
										Wylie <span>(와일리)</span>
									</p>
									<div className='comp_info'>
										<p className='date'>2021.09 ~ 재직중</p>
										<p className='info'>컨버전스본부 개발그룹 / 매니저</p>
									</div>
									<span className='chess_01'></span>
									<div className='control_btn prev'>
										<button className='prev_01' onClick={prev01}>이전</button>
									</div>
								</div>
							</div>
						</div>

						<div className='page_wrap cnt2'>
							<div className='pages_page page_03 front'>
								<div className='pages_page__inner'>
									<button type="button" className='book_home' onClick={home}><span className='blind'>처음으로</span></button>
									<div className='work_txt'>
										<h4>주요업무</h4>
										<ul>
											<li>커머스/멤버십/헬스케어 구축 퍼블리싱</li>
											<li>퍼블리싱 PL 경험 및 가이드 작성</li>
											<li>반응형, 적응형, Mobile App 내 Web View 영역 작업 등 다양한 환경 작업</li>
										</ul>
									</div>
									<div className='skill_list'>
										<h4>기술 Stack</h4>
										<ul>
											<li>
												<figure>
													<img src="/img/ico_html.png" alt="html" />
												</figure>
												<p>HTML</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_css.png" alt="css" />
												</figure>
												<p>CSS</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_sass.png" alt="Sass" />
												</figure>
												<p>Sass</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_js.png" alt="javascript" />
												</figure>
												<p>JS</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_figma.png" alt="figma" />
												</figure>
												<p>Figma</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_xd.png" alt="XD" />
												</figure>
												<p>XD</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_git.png" alt="Git" />
												</figure>
												<p>Git</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_react.png" alt="React" />
												</figure>
												<p>React</p>
											</li>
										</ul>
									</div>
									<div className='control_btn next'>
										<button className='next_02' onClick={next02}>다음</button>
									</div>
								</div>
							</div>
							<div className='pages_page page_04 back'>
								<div className='pages_page__inner'>
									<p className='logo logo_w'>
										Futurenuri <span>(퓨쳐누리)</span>
									</p>
									<div className='comp_info'>
										<p className='date'>2020.08 ~ 2021.08</p>
										<p className='info'>정보기술연구소 / 사원</p>
									</div>
									<div className='control_btn prev'>
										<button className='prev_02' onClick={prev02}>이전</button>
									</div>

								</div>
							</div>
						</div>

						<div className='page_wrap cnt3'>
							<div className='pages_page page_05 front'>
								<div className='pages_page__inner'>
									<button type="button" className='book_home' onClick={home}><span className='blind'>처음으로</span></button>
									<div className='work_txt'>
										<h4>주요업무</h4>
										<ul>
											<li>대학도서관 홈페이지 구축</li>
											<li>웹접근성 기반한 UI 개발 및 인증 마크 획득</li>
											<li>크로스 브라우징 테스트 및 반응형 퍼블리싱</li>
										</ul>
									</div>
									<div className='skill_list'>
										<h4>기술 Stack</h4>
										<ul>
											<li>
												<figure>
													<img src="/img/ico_html.png" alt="html" />
												</figure>
												<p>HTML</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_css.png" alt="css" />
												</figure>
												<p>CSS</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_js.png" alt="javascript" />
												</figure>
												<p>JS</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_ps.png" alt="PSD" />
												</figure>
												<p>PSD</p>
											</li>
											<li>
												<figure>
													<img src="/img/ico_git.png" alt="Git" />
												</figure>
												<p>Git</p>
											</li>
										</ul>
									</div>
									<div className='control_btn next'>
										<button className='next_03' onClick={next03}>다음</button>
									</div>
								</div>
							</div>
							<div className='pages_page page_06 back'>
								<div className='pages_page__inner'>
									<div className='back_txt'>
										<span className='mark'>?</span>
										<p>Next Page ?</p>
									</div>
									<div className='control_btn prev'>
										<button className='prev_03' onClick={prev03}>이전</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Book;