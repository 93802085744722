import React, { useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";
import card from '../../css/components/card.module.scss'

const Card = () => {
	const [isActive, setIsActive] = useState('about');
	const [isScrolled, setIsScrolled] = useState(false);

	const scrollToBottom = () => {
		window.scrollTo({
			top: document.documentElement.scrollHeight,
			behavior: 'smooth'
		})
	}

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			if(scrollPosition > 0 && !isScrolled) {
				setIsScrolled(true);
			}else if (scrollPosition <= 20 && isScrolled) {
				setIsScrolled(false);
			}
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isScrolled])


	return (
		<section className={isScrolled ? card.scrolled : ''}>
			<div className={card.container}>
				<div className={card.container_wrap}>
					<div className={card.container_inner}>
						<div className={card.content_body}>
							<div className={card.body_inner}>
								<div className={card.visual} id={card[isActive]}>
									<div className={card.my_card}>
										{/* 앞면 */}
										<div className={[card.card_print, card.card_f].join(" ")}>
											<figure className='img'>
												<img src="/img/card_front.png" />
											</figure>
										</div>
										{/*// 앞면 */}

										{/* 뒷면 */}
										<div className={[card.card_print, card.card_b].join(" ")}>
											<div className={card.card}>
												<div className={card.header}>
													<figure className={card.avatar}><img src="/img/thumb.png" alt="avatar" /></figure>
													<div className={card.txt_wrap}>
														<p>CHO MINHYEOK</p>
														<span>UI Developer</span>
													</div>
												</div>
												<div className={card.main}>
													{/* ABOUT */}
													<div className={isActive ==="about" ? card.isActive : null } id="about">
														<div className={card.content}>
															<h2>ABOUT</h2>
															<p className={card.about_desc}>
																안녕하세요. 5년차 UI 개발자 조민혁입니다 :)<br/>
																단순하지만 유연하고 재사용성이 높은 코드를 지향합니다.<br/>
																지속적인 자기개발을 통해 SPA 프레임워크와 같은 최신 기술에 대한 이해를 높이고,
																UI 개발 분야에서의 지식과 스킬을 계속적으로 향상시켜 나가고 있습니다!
															</p>
														</div>
														<div className={card.social}>
															<a href="https://bit.ly/mhcho-Portfolio" target='_blank'>
																<img src="/img/ico_notion.png" />
															</a>
															<a href="https://haagenmoa.tistory.com/" target='_blank'>
																<img src="/img/ico_blog.png" />
															</a>
														</div>
													</div>
													{/*// ABOUT */}

													{/* EXPERIENCE */}
													<div className={isActive ==="experience" ? card.isActive : null } id="experience">
														<div className={card.content}>
															<h2>WORK EXPERIENCE</h2>
															<div className={card.timeline}>
																<div className={card.item} data-year="2024">
																	<h3>와일리</h3>
																	<p>커머스 및 멤버십 구축 UI 개발</p>
																</div>
																<div className={card.item} data-year="2021">
																	<h3>퓨처누리</h3>
																	<p>도서관 및 검색 솔루션 구축</p>
																</div>
															</div>
														</div>
													</div>
													{/*// EXPERIENCE */}

													{/* CONTACT */}
													<div className={isActive ==="contact" ? card.isActive : null } id="contact">
														<div className={card.content}>
															<h2>CONTACT</h2>
															<div className={card.wrap}>
																<div className={card.contact}>
																	<i>
																		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
																		<path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"></path></svg>
																	</i>
																	<p>010-9927-3400</p>
																</div>
																<div className={card.contact}>
																	<i>
																		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
																		<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
																		<path d="M22 6l-10 7L2 6"></path></svg>
																	</i>
																	<p>whalsgur0000@naver.com</p>
																</div>
																<a href="#" onClick={(e) => { e.preventDefault(); scrollToBottom()}} className={card.me}>WORK TOGETHER</a>
															</div>
														</div>
													</div>
													{/*// CONTACT */}
												</div>

												<div className={card.btn}>
													<button className={isActive ==="about" ? card.isActive : null } onClick={() => setIsActive('about')} data-section="#about">ABOUT</button>
													<button className={isActive ==="experience" ? card.isActive : null } onClick={() => setIsActive('experience')} data-section="#experience">EXPERIENCE</button>
													<button className={isActive ==="contact" ? card.isActive : null } onClick={() => setIsActive('contact')} data-section="#contact">CONTACT</button>
												</div>
											</div>
										</div>
										{/*// 뒷면 */}
									</div>
									{/* <div className={card.mar}>
										<Marquee>
											<div className={card.marquee}>
												<p>A SMALL THING HAS MADE A BIG THINGS.</p>
											</div>
											<div className={card.marquee}>
												<p>A SMALL THING HAS MADE A BIG THINGS.</p>
											</div>
										</Marquee>
									</div> */}

									<div className={card.down}>
										<p>SCROLL DOWN</p>
										<i></i>
									</div>
								</div>
							</div>
						</div>
						<div className={card.content_bg}>
							<p className={card.main_txt}>완벽한 UI를 <br/> 선물합니다! </p>
							{/* <p className={card.main_txt}>플랙서블한 <br/> UI 개발자! </p> */}
							{/* <p className={card.main_txt}>유연한 생각,<br/> 유려한 코드! </p> */}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Card;