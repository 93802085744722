import React from 'react';
import {Routes, Route, HashRouter } from 'react-router-dom';
import Main from './Main';
import Detail from './sub/Detail';
import ScrollToTop from './ScrollToTop';


const Router = () => {
	return (
		<HashRouter basename={process.env.PUBLIC_URL}>
			<ScrollToTop/>
			<Routes>
				{/* useParams */}
				<Route path="/" element={<Main/>}/>
				<Route path="/detail/:id" element={<Detail/>}/>
			</Routes>
		</HashRouter>
	);
};

export default Router;