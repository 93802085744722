import React, { useRef, useEffect } from 'react';


const Mouse = () => {
	const cursorRef = useRef(null);
	let mouseX = 0;
	let mouseY = 0;
	let cursorX = 0;
	let cursorY = 0;
	let speed = 0.3;


	useEffect(() => {
		const animate = () => {
			if (cursorRef.current) { // Check if cursorRef.current is not null
				let distX = mouseX - cursorX;
				let distY = mouseY - cursorY;

				cursorX = cursorX + (distX * speed);
				cursorY = cursorY + (distY * speed);

				cursorRef.current.style.left = cursorX + 'px';
				cursorRef.current.style.top = cursorY + 'px';
			}

			requestAnimationFrame(animate);
		};

		animate();

		const handleMouseMove = (e) => {
			mouseX = e.pageX - 10;
			mouseY = e.pageY - 10;
		};

		document.body.addEventListener('mousemove', handleMouseMove);

		return () => {
			document.body.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<div id="cursor" ref={cursorRef}></div>
	);
};

export default Mouse;