import React from 'react';
import list from '../../css/components/list.module.scss'

const Tag = ({tag}) => {
	const tagList = tag.map((tags, index) => (<li key={index}>{tags}</li>))
	return (
		<>
			<ul className={list.tag}>{tagList}</ul>
		</>
	);
};

export default Tag;